.carousel-wrapper {
    width: 85%;
    margin: auto;
    margin-top: 35px;
    margin-bottom: 35px;
    margin-left: 125px;
    z-index: 0;
}

.swiper {
    height: 320px;
}

.swiper-slide {
    width: 185px !important;
    
}

.swiper-pagination-bullet {
    background-color: #615D6C;
}

.swiper-pagination-bullet-active {
    background-color: #165032;
}


.carousel-book {
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1.1px 1.5px,
      rgba(0, 0, 0, 0.1) 0px 2.8px 3.9px, rgba(0, 0, 0, 0.08) 0px 5.8px 7.9px,
      rgba(0, 0, 0, 0.06) 0px 12.0455px 16.4px, rgba(0, 0, 0, 0.04) 0px 33px 45px;
    border-radius: 3px;
    overflow: hidden;
  }
  
  .carousel-book img {
    border-radius: 3px;
    display: block;
    height: auto;
    object-fit: cover;
    width: 100%;
    width: 179px; 
    height: 288px;
  }
  
  /* Book Cover Effect */
  .carousel-book::before {
    content: "";
    position: absolute;
    inset: 0px;
    border-radius: 3px;
    pointer-events: none;
    filter: contrast(310%) brightness(100%);
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.118) 0.65%,
      rgba(255, 255, 255, 0.2) 1.53%,
      rgba(255, 255, 255, 0.1) 2.38%,
      rgba(0, 0, 0, 0.05) 3.26%,
      rgba(255, 255, 255, 0.14) 5.68%,
      rgba(244, 244, 244, 0) 6.96%
    );
  }
  