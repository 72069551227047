

body {
  background-color: #E9EBF8;
  color: #615D6C;
  background-image: url("../images/background1.png");
  background-repeat: repeat;
  background-size: 30%;
  background-attachment: fixed;
  height: 100vh;
}

a {
  color: #5DA271;
  text-decoration: none;
}

a:hover {
  color: #165032;
}

a:visited {
  color: #615D6C;
}


    /* CONTENT */
  
    .content-header-wrapper {
      background-color: #E9EBF8;
      width: 87%;
      margin: auto;
    }

    .page-content {
      background-color: #E9EBF8;
      color: #615D6C;
      padding: 15px;
      font-size: 110%; 
      width: 85%;
      margin: auto;
      margin-top: 100px; /* IF  YOU CHANGE THIS, CHANGE THE -100 IN HEADER.CSS I HATE THIS*/
    }

    .page-content .section {
      padding: 25px 50px;
      width: 100%;
    }

    .page-content .section .section-header-wrapper {
      padding-left: 10px;
      border-left: 5px solid #165032;
    }

    .page-content .section .section-header {
      font-size: 125%;
      font-family: "Inter", sans-serif;
      font-weight: 300;
      text-transform: uppercase;
      font-style: normal;
      margin-bottom: 5px;
    }

    .page-content .section .banner {
      font-size: 125%;
      font-style: italic;
      color: #5DA271;
    }


    .section-inner-wrapper {
      margin: 50px 50px;
      width: 100%;
      display: inline-block;
    }

    .page-content .section .section-text {
      width: 60%;
      float: left;
    }

    .page-content .section-image {
      width: 30%;
      float: left;
    }


    .page-content .section .section-image img{
      margin-left: 20%;
      margin-top: 10%;
      box-shadow: 12px 10px 0px 0px #5DA271;
    }

    .page-content .section-right .section-image img{

      margin-right: 20%;
      margin-left: 0;
      margin-top: 10%;
      box-shadow: 12px 10px #5DA271;
      box-shadow: -12px 10px 0px 0px #5DA271;
    }
  
    


    .clear{
      clear: both;
      float: left;
    }

    /* FOOTER */
  
  .footer {
    background-color: #E9EBF8;
    text-align: center;
    width: 85%;
    inset: 0;
    height: fit-content;
    margin: auto;
    font-size: 12px;
  }

  .footer .privacy-button {
    cursor: pointer;
    color: #5DA271;
  }

  .footer .privacy-button:hover {
    cursor: pointer;
    color: #165032;
  }
  



/* TEXT DIVIDER */

  /* 🐞 codyhouse.co */
.text-divider {
  --text-divider-gap: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 0 25px;
  clear: both;
}

.text-divider::before, .text-divider::after {
  content: '';
  height: 1px;
  background-color: #165032;
  flex-grow: 1;
}

.text-divider::before {
  margin-right: var(--text-divider-gap);
}

.text-divider::after {
  margin-left: var(--text-divider-gap);
}

