/* HEADER */

.header {
    z-index: 1000;
    position: fixed;
    width: 85%;
    height: 85px;
    background-color: #E9EBF8;
    color: #444;
    padding: 11px;
    font-size: 150%; 
    margin: 0% auto; /* Only centers horizontally not vertically! */
    left: 0;
    right: 0;
    margin-top: -100px;

    -webkit-box-shadow: 0 2px 2px -2px #165032;
    -moz-box-shadow: 0 2px 2px -2px #165032;
    box-shadow: 0 2px 2px -2px #165032;

}

  
    /*centering things is fucking horrible*/
    .header-inner-wrapper {
      width: 100%;
      position: fixed;
    }
  
    .header .logo {
      width: 40%;
      float: left;
      text-align: right;
      margin-top: 10px;
      cursor: pointer;
    }
  
    .header .navigation {
      /* font-family: "Inter", sans-serif; */
      font-family: fixedsys;
      text-transform: uppercase;
      font-style: normal;
      font-size: 75%;
      width: 50%;
      float: left;
      text-align: left;
      margin-top: 3.5%;
      margin-left: 25px;
    }
  
    .header .navigation .nav-button {
      padding: 15px;
      cursor: pointer;
      color: #5DA271;
    }
  
    .header .navigation .nav-button:hover {
      color: #165032;
      font-weight: 400;
    }
  